<template>
  <div
    :class="[{'pr-2 md:pr-4 lg:pr-8 pb-8 w-full md:w-1/2 lg:w-1/2 xl:w-1/3': isSingle, 
      'pb-12 w-full md:w-1/2 tablet:w-1/3 xl:w-1/3': !isSingle,}]"
    class="flex"
  >
    <div
      :class="[{'shadow-md w-full p-4': isSingle, 'pr-5': !isSingle, 
        'cursor-pointer': isAvailable, 'cursor-pointer': !isAvailable}]"
      class="bg-white rounded-lg flex flex-wrap"
    >
      <div class="self-start w-full">
        <div v-if="!isAvailable" class="block w-full text-primary-500 pb-2 text-right text-sm font-semibold">
          Полный просмотр видео доступен только зарегистрированным пользователям
        </div>
        <div v-if="isAvailable"> 
          <div v-if="isUserActive">
            <button
              v-if="!isFavourite"
              @click="addToFavourites(id)"
              class="block w-full text-gray-400 text-right text-lg font-semibold hover:text-primary-400"
            >
              <span class="text-sm">Сохранить в избранное</span><font-awesome-icon icon="star" class="ml-1"/>
            </button>
            <button
              v-if="isFavourite"
              @click="deleteFromFavourites(id)"
              class="block w-full text-lg text-right font-semibold text-primary-600 hover:text-primary-400"
            >
              <span class="text-sm">В избранном</span><font-awesome-icon icon="star" class="ml-1"/>
            </button>
          </div>
        </div>
      </div>

      <!-- Активная карточка -->
      <router-link class="flex flex-wrap cursor-pointer" :to="link" v-if="isAvailable">
        <p class="self-start text-sm text-left pt-2 text-secondary font-semibold">Эксперт: {{ expert }}</p>
        <h2 class="self-start text-xl font-medium text-gray-700 max-w-lg pt-1 leading-6 mx-auto text-left hover:text-primary-500">
          {{ title }}
        </h2>
        <div class="self-end pt-3">
          <div class="relative">
            <img
              class="m-0 px-0 w-full"
              :src="require('@/assets/img/video-thumbs/' + id + '.jpg')"
            />
            <span class="bg-white text-gray-700 text-xl absolute bottom-0 mr-1 p-1 mb-1 right-0 rounded-tl-md">
              {{ likes }}
              <font-awesome-icon icon="heart" 
                class="ml-1"
                :class="[{'text-primary-500':isLiked}]"
              />
            </span>
          </div>
          <p v-if="specialities" class="text-sm text-left pt-2 mx-auto text-gray-600 font-semibold">Для специальностей: {{ specialities }}</p>
          <SolidButton v-if="isAvailable" title="Смотреть" :link="link" />
        </div>
      </router-link>

      <!-- Заблокированная карточка -->
      <router-link :to="link" v-if="!isAvailable" class="flex flex-wrap">
        <p class="self-start text-sm text-left pt-2 text-secondary font-semibold">
          Эксперт: {{ expert }}
        </p>
        <h2 class="self-start text-xl font-medium text-gray-700 max-w-lg pt-1 leading-6 mx-auto text-left hover:text-primary-500">
          {{ title }}
        </h2>
        <div class="self-end pt-3">
          <div class="relative">
            <img class="m-0 px-0 w-full" :src="require('@/assets/img/video-thumbs/' + id + '.jpg')" />
            <span class="bg-white text-gray-700 text-xl absolute bottom-0 mr-1 p-1 mb-1 right-0 rounded-tl-md">
              {{ likes }}
              <font-awesome-icon icon="heart" class="text-primary-500 ml-1"/>
            </span>
            <!--<div class="shaded flex justify-center items-center absolute top-0 left-0 w-full h-full">
              <font-awesome-icon icon="lock" class="align-middle" />
            </div>-->
          </div>
          <p v-if="specialities" class="text-sm text-left pt-2 mx-auto text-gray-600 font-semibold">
            Для специальностей: {{ specialities }}
          </p>
          <SolidButton title="Смотреть" :link="link" />
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import SolidButton from '@/components/_partials/SolidButton.vue'
import BlockedButton from '@/components/_partials/BlockedButton.vue'

export default {
  name: 'VideoCard',
  components: {
    SolidButton,
    BlockedButton
  },  
  props: {
    id: Number,
    title: String,
    expert: String,
    imgurl: String,
    isFavourite: Boolean,
    specialities: String,
    likes: Number,
    isLiked: Boolean,
    link: String,
    type: String, //single - для отображения на своих карточках, //part - для отображения в одной области
    isAvailable: Boolean
  },
  computed: {
    isSingle() {
      if (this.type == "single")
        return true;
      else 
        return false;  
    },
    ...mapGetters({
      isUserActive: 'auth/isUserActive'
    }) 
  },
  methods: {
    ...mapActions({
      addToFavourites: 'videos/addToFavourites', 
      deleteFromFavourites: 'videos/deleteFromFavourites'
    })
  }
}
</script>

<style> 
  .shaded {
    font-size: 6rem;
    color: #ffffffba;
    background-color: #cbd5e091;
  }
</style>
